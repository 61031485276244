




















































































































































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import AuthUser from "../../global-config/AuthUser";
import UserService from "../service/UserService";
import AlertService from "../service/AlertService";
import VerifyEmail from "./VerifyEmail.vue";
import { IUser } from "../models/User";
import UniversalService from "../../common-app/service/UniversalService";
import { IDivision } from "../../common-app/models/Division";
import { IDistrict } from "../../common-app/models/District";
import { IThana } from "../../common-app/models/Thana";
import DoctorDetailService from "../../doctor-app/service/DoctorDetailService";
import { IDoctorDetail } from "../../doctor-app/models/DoctorDetail";

@Component({
  components: {
    VerifyEmail,
  },
})
export default class ProfileWidget extends Vue {
  @Prop({ default: 0 }) consultationFee: number;
  @Prop({ default: "" }) title: string;
  @Prop({ default: "" }) address: string;
  @Prop({ default: false }) isDetailsProfile: boolean;
  @Prop({ default: "" }) imageUrl: string;
  @Prop({ default: "" }) fName: string;
  @Prop({ default: "" }) lName: string;
  @Prop({ default: 1 }) gender: number;
  @Prop({ default: false }) isEmailVerify: boolean;
  @Prop({ default: false }) isVirtualChamber: boolean;
  public user = AuthUser.getAuthUser();
  public url: any = "";
  public formData: FormData = new FormData();
  public verifyEmailModal: boolean = false;
  public isShow: Boolean = false;
  public fullName: string = AuthUser.getFullName();
  public userDetail: IUser = {} as IUser;
  public divisions: IDivision[] = [];
  public districts: IDistrict[] = [];
  public thanas: IThana[] = [];
  public selectedThanas: IThana[] = [];
  public isShowEdit: boolean = false;
  public isDoctorDashboatd: boolean = false;
  public detail: IDoctorDetail = {} as IDoctorDetail;

  public submited: boolean = false;
  imgSrc: any = null;
  cropImg: any = "";
  fileName: string = "";

  $refs: {
    cropper: HTMLFormElement;
  };

  // handleFileUpload(e: any) {
  //   debugger;
  //   const file = e.target.files[0];
  //   this.url = URL.createObjectURL(file);
  // }

  handleFileUpload(event: any) {
    this.formData.append("file", event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.url = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  save() {
    const base64Data = this.$refs.cropper.getCroppedCanvas().toDataURL();
    let extention = base64Data.substring("data:image/".length, base64Data.indexOf(";base64"));
    const filename = new Date().getTime() + "." + extention;
    var file = this.dataURLtoFile(this.$refs.cropper.getCroppedCanvas().toDataURL(), filename);
    this.formData.append("file", file);

    UserService.fileUpload(this.formData).then((res) => {
      this.user = Object.assign({}, this.user, { imageUrl: res.data });
      this.imgSrc = "";
      this.$bvModal.hide("upload-profile-photo");
      AuthUser.setToken(Object.assign({}, AuthUser.getAuthUser(), { imageUrl: res.data }));
      AlertService.sendMessage({ status: true, message: "File has been uploaded Successfully" });
    });
  }

  update(e: any) {
    this.userDetail.firstName = this.fullName;
    this.userDetail.email = this.user.email;
    this.userDetail.phone = this.user.phone;
    if (this.userDetail.firstName != "" && this.userDetail.email != "" && this.userDetail.phone.length != 0) {
      this.submited = true;
      UserService.Update(this.userDetail).then((res) => {
        if (res.data.status) {
          AlertService.sendMessage({ status: true, message: "Please sign out sign in back to see the changes" });
          this.userDetail = Object.assign({}, res.data.result || {});
          this.submited = false;
        } else {
          this.submited = true;
          AlertService.sendMessage(res.data.message);
        }
      });
    }
  }

  setImage(e: any) {
    const file: File = e.target.files[0];
    if (!file.type.includes("image/")) {
      AlertService.sendMessage({ status: false, message: "Please select and image file" });
      return;
    }
    this.fileName = file.name;
    if (typeof FileReader === "function") {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgSrc = event.target.result;
        // rebuild cropperjs with the updated source
        this.$refs.cropper.replace(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      AlertService.sendMessage({ status: false, message: "Sorry, FileReader API not supported" });
    }
  }
  cropImage() {
    // get image data for post processing, e.g. upload or setting image src
    this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
  }

  rotate() {
    this.$refs.cropper.rotate(90);
  }
  created() {
    UniversalService.GetAddress().then((res) => {
      this.divisions = res.data.divisions;
      this.districts = res.data.districts;
      this.thanas = res.data.thanas;
      this.selectedThanas = this.thanas;
    });

    if (AuthUser.IsEmailLogin()) {
      this.isShow = true;
      this.isEmailVerify = true;
    }
    // if(AuthUser.isLoggednIn()){
    //  this.isShow=true;
    // }
    UserService.GetAddress().then((res) => {
      this.userDetail.address = res.data.result.address;
      this.userDetail.districtId = res.data.result.districtId;
      this.userDetail.thanaId = res.data.result.thanaId;
    });

    DoctorDetailService.getProfileList().then((s) => {
      this.detail = s.data.doctorDetails || ({} as IDoctorDetail);
      //console.log(this.detail);
    });
    this.fullName = this.user.firstName + " " + this.user.lastName;

    if (this.$route.path == "/user" || this.$route.path == "/doctor/dashboard") {
      this.isShowEdit = true;
      //alert(this.isShowEdit);
    }
    if (this.$route.path == "/doctor/dashboard") {
      this.isDoctorDashboatd = true;
    }
    //console.log(this.$route.path);
  }
  dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  openVerifyEmailModal() {
    this.verifyEmailModal = true;
  }

  verifyEmailClosed() {
    this.verifyEmailModal = false;
  }
  onChange(event: any) {
    this.selectedThanas = this.thanas.filter((s) => s.districtId === Number(event.target.value));
    this.userDetail.thanaId = event.target.value;
  }
}
